import * as getters from "./getters.js";
import * as mutations from "./mutations.js";
import * as actions from "./actions.js";

const state = {
  model: "orders",
  items: [],

  constants: {
    status: {
      1: {
        id: 1,
        label: "New",
        colors: {
          bg: "bg-blue-400",
          text: "text-white",
        },
      },
      2: {
        id: 2,
        label: "In process",
        colors: {
          bg: "bg-yellow-400",
          text: "text-white",
        },
      },
      3: {
        id: 3,
        label: "Finished",
        colors: {
          bg: "bg-emerald-400",
          text: "text-white",
        },
      },
      4: {
        id: 4,
        label: "Error",
        colors: {
          bg: "bg-red-400",
          text: "text-white",
        },
      },
    },
    insurance_status: {
      0: {
        id: 0,
        label: "Not active",
        colors: {
          bg: "bg-red-400",
          text: "text-white",
        },
      },
      1: {
        id: 1,
        label: "Active",
        colors: {
          bg: "bg-emerald-400",
          text: "text-white",
        },
      },
    },
    is_delivery: {
      0: {
        id: 0,
        label: "Not",
        colors: {
          bg: "bg-red-400",
          text: "text-white",
        },
      },
      1: {
        id: 1,
        label: "Yes",
        colors: {
          bg: "bg-emerald-400",
          text: "text-white",
        },
      },
    },
  },

  pagination: {
    limit: 25,
  },

  loading: false,

  viewIndexData: {
    pageTitle: "Orders",
    tableHeads: [
      { title: "Order ID", key: "order_id" },
        { title: "Start date", key: "from_date", format: "MM/DD/YYYY" },
        { title: "End date", key: "to_date", format: "MM/DD/YYYY" },
        { title: "Destination", key: "country_name" },
      { title: "First name", key: "first_name" },
      { title: "Last name", key: "last_name" },
      { title: "Email", key: "email" },
      { title: "Total amount", key: "total_amount" },
      {
        title: "Order status",
        key: "order_status",
        type: "badge",
        constant: "status",
      },
    ],
    tableProps: {
      create: true,
      show: true,
      destroy: false,
      edit: false,
      group: false,
      search: true,
      downloadExcel: true,
      itemsLimit: true,
    },
  },

  viewCreateData: {
    pageTitle: "Create order",
  },

  viewDetailData: {
    pageTitle: "Order details",
    fields: [
      { text: "Order ID", key: "order_id" },
      {
        text: "Order status",
        key: "order_status",
        type: "badge",
        constant: "status",
      },
      {
        text: "From date",
        key: "from_date",
        type: "date",
        format: "MM/DD/YYYY",
      },
      { text: "To date", key: "to_date", type: "date", format: "MM/DD/YYYY" },
      { text: "Number of days", key: "number_of_days" },
      { text: "Number of devices", key: "number_of_devices" },
      { text: "Plan amount", key: "plan_amount" },
      { text: "Total amount", key: "total_amount" },
      {
        text: "Insurance status",
        key: "insurance_status",
        type: "badge",
        constant: "insurance_status",
      },
      { text: "Insurance amount", key: "insurance_amount" },
      { text: "Continent name", key: "continent_name" },
      { text: "Country name", key: "country_name" },
      { text: "Country package name", key: "country_package_name" },
      { text: "Customer api ID", key: "customer_api_id" },
      { text: "First name", key: "first_name" },
      { text: "Last name", key: "last_name" },
      { text: "Email", key: "email" },
      { text: "Contact number", key: "contact_number" },
      { text: "Address", key: "address" },
      { text: "City", key: "city" },
      { text: "Country", key: "country" },
      { text: "State", key: "state" },
      { text: "ZIP Code", key: "zip_code" },
      { text: "Payment ID", key: "payment_id" },
      {
        text: "Is delivery",
        key: "is_delivery",
        type: "badge",
        constant: "is_delivery",
      },
      { text: "Pickup location name", key: "pickup_location_name" },
      { text: "Coupon code", key: "coupon_code" },
      { text: "Coupon amount", key: "coupon_amount" },
      { text: "Shipping type name", key: "shipping_type_name" },
      { text: "pickup_dt_tm", key: "pickup_dt_tm" },
      { text: "pickup_time", key: "pickup_time" },
      { text: "payment_mode", key: "payment_mode" },
      { text: "requested_ip", key: "requested_ip" },
      { text: "requested_os", key: "requested_os" },
      { text: "requested_device", key: "requested_device" },
      { text: "transaction_reference_id", key: "transaction_reference_id" },
      { text: "stripe_customer_id", key: "stripe_customer_id" },
      { text: "stripe_payment_response", key: "stripe_payment_response" },
    ],
  },

  viewEditData: {
    pageTitle: "Edit order",
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
