<template>
    <td
        v-if="head && item"
        class="px-6 py-4"
    >
        {{ parseItem(item, head) }}
    </td>
</template>

<script>
import moment from "moment";
export default {
    name: "DefaultColumn",

    props: {
        head: {
            type: Object,
            default() {
                return null;
            }
        },
        item: {
            type: Object,
            default() {
                return null;
            }
        },
    },

    methods: {
        parseItem(item, head) {
            if (head.format) {
                return moment(item[head.key]).format(head.format);
            }

            return item[head.key];
        }
    }
}
</script>

<style scoped>

</style>